<template>
  <main>
    <section class="js-page page join">
      <div class="page-content">
        <h2>{{ $t(`dashboardWelcome.title`) }}</h2>
        <br />
        <div>
          {{ $t(`dashboardWelcome.text`) }}
        </div>
        <br />
        <div class="searched-bar">
          <div class="searched-show">
            {{ $t(`dashboardWelcome.show`) }} {{ projects.length }}
            {{ $t(`dashboardWelcome.project`) }}
          </div>
          <div class="searched-sort">
            <!-- Sort by: <span class="post-time">Newest Project</span -->
            <!-- ><span class="menu-icon">▼</span> -->
          </div>
        </div>
        <div class="job-cards">
          <div class="job-card new-job" @click="newProject()">
            <div class="symbol">+</div>
            New Project
          </div>
          <div class="job-card" v-for="type in projects" :key="type.id">
            <div class="job-card-image-wrapper">
              <img v-if="type.img" :src="type.img" />
              <img v-else src="../assets/images/placeholder_bg.png" />
              <div
                class="overlay"
                @click="$router.push(`/projects/${type.id}`)"
              ></div>
              <div
                v-if="latestJobID(type)"
                class="quick-link-bar"
                @click="$router.push(`/viewer?jobId=${latestJobID(type).id}`)"
              >
                <img
                  src="https://assets.website-files.com/5beab1239ac88487c3a6608f/6098ce2566be8b2273b3efa3_Preview%20Icon.svg"
                  class="quick-link-icon"
                />
              </div>
            </div>
            <div
              class="job-card-wrapper"
              v-if="type"
              @click="$router.push(`/projects/${type.id}`)"
            >
              <!-- <div class="job-card-avatar-large"></div> -->
              <div class="job-card-category">
                {{ type.category ? type.category : "Undefined" }}
              </div>
              <div class="job-card-time">
                {{ type.createdDate.split("T")[0] }}
              </div>
              <div class="job-card-title">{{ type.name }}</div>
              <div class="job-card-subtitle">
                {{ type.jobs.length == 1 ? "Simulation: " : "Simulations: " }}
                <ul class="job-card-iterations">
                  <li
                    class="iteration"
                    v-for="job in type.jobs"
                    :key="job"
                  ></li>
                </ul>
              </div>
              <div class="job-detail-buttons">
                <button class="search-buttons detail-button">
                  <Icon v-if="type.accessType">lock_open</Icon>
                  <Icon v-else>lock</Icon>
                  {{ type.accessType ? "Public" : "Privat" }}
                </button>
              </div>
              <div class="job-card-buttons">
                <!-- <button class="search-buttons card-buttons">
                  Open project
                </button> -->
                <!-- <Icon>view_in_ar</Icon> -->
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script >
import { usePerms } from "@/logic/usePerms"
import axios from "axios"
import { computed, defineComponent, ref } from "vue"
import { useRoute, useRouter } from "vue-router"
import { useStore } from "vuex"
import Button from "../components/Button.vue"
import Icon from "../components/Icon.vue"

export default defineComponent({
  components: {
    Button,
    Icon,
  },
  setup(props) {
    const store = useStore()
    const { hasPerm, checkToken } = usePerms()
    const router = useRouter()
    const token = computed(() => store.getters["auth/token"])
    const projects = ref([])
    // const url = "http://localhost:8085"
    const url = "https://api.otonomi.no"

    const latestJobID = (project) => {
      const jobs = project.jobs.filter((v) => {
        return v.status == -1
      })
      return jobs.reverse()[0]
    }

    const loadProjects = async () => {
      if (!store.getters["auth/isLogedIn"]) {
        store.commit("setModal", 2)
        return
      }
      await checkToken()

      axios({
        baseURL: url,
        url: "/project",
        data: {},
        method: "GET",
        headers: {
          Authorization: "Bearer " + token.value,
        },
      })
        .then((resp) => {
          console.log(resp)
          projects.value = resp.data.reverse()
        })
        .catch((err) => {
          console.log(err)
        })
    }
    const newProject = async () => {
      if (!store.getters["auth/isLogedIn"]) {
        store.commit("setModal", 2)
        return
      }
      await checkToken()

      axios({
        baseURL: url,
        url: "/project",
        data: {},
        method: "POST",
        headers: {
          Authorization: "Bearer " + token.value,
        },
      })
        .then((resp) => {
          console.log(resp)
          router.push(`/projects/${resp.data.id}/${resp.data.jobs[0].id}`)

          // projects.value = p
        })
        .catch((err) => {
          console.log(err)
        })
    }
    loadProjects()
    return {
      projects,
      newProject,
      latestJobID,
    }
  },
})
</script>

<style lang="scss" scoped>
// @import "../css/join.scss";

.page-content {
  width: 800px;
  margin: 300px auto 0;

  @media (max-width: 800px) {
    width: 500px;
    margin-top: 150px;
  }
  @media (max-width: 500px) {
    width: 240px;
  }
}
.progress-bar {
  height: 4px;
  width: 100%;
  overflow: hidden;
  border-radius: 2px;
  background-color: #dadff3;
  margin: 6px 0;

  .progress {
    height: 100%;
    width: 40%;
    background-color: #4bc0dd;
    display: block;
  }
}

.searched-bar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  -webkit-animation: slideY 0.6s both;
  animation: slideY 0.6s both;

  .searched-show {
    font-size: 19px;
    font-weight: 600;
  }
  .searched-sort {
    font-size: 14px;
    color: var(--color-text-light);
    .post-time {
      font-weight: 600;
      color: var(--color-text-light);
    }
    .menu-icon {
      font-size: 9px;
      color: var(--color-text-light);
      margin-left: 6px;
    }
  }
}

.job-cards {
  text-align: left;
  width: 100%;
  padding-top: 20px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 25px;
  grid-row-gap: 25px;
  -webkit-animation: slideY 0.6s both;
  animation: slideY 0.6s both;

  @media (max-width: 800px) {
    grid-template-columns: repeat(2, 1fr);
  }
  @media (max-width: 500px) {
    grid-template-columns: repeat(1, 1fr);
  }

  .job-card {
    background-color: white;
    border-radius: 8px;
    cursor: pointer;
    transition: 0.2s;
    overflow: hidden;

    &.new-job {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      background: var(--color-blue);
      box-shadow: var(--shadow-dark);
      color: white;

      .symbol {
        font-size: 4rem;
        font-weight: 700;
      }
    }

    &:hover {
      // transform: scale(1.02);

      .job-card-image-wrapper {
        .quick-link-bar {
          transform: translate3d(0px, 0px, 0px) scale3d(1, 1, 1) rotateX(0deg)
            rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
          transform-style: preserve-3d;
        }
      }
    }

    .job-card-image-wrapper {
      position: relative;
      overflow: hidden;
      height: 135px;
      background: var(--color-bg-dark);

      .overlay {
        opacity: 0;
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        display: block;
        background: #000000;
        transition: all 0.3s ease-in-out;
      }
      img {
        width: 100%;
        object-fit: cover;
        transition: all 0.3s ease-in-out;
        transform: translateZ(0);
      }
      &:hover {
        .overlay {
          opacity: 0.2;
        }
        img {
          transform: scale(1.1);
          transform-style: preserve-3d;
        }
      }
      .quick-link-bar {
        position: absolute;
        left: auto;
        top: auto;
        right: 10px;
        bottom: 10px;
        z-index: 5;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        padding: 4px 10px;
        -webkit-box-align: center;
        -webkit-align-items: center;
        -ms-flex-align: center;
        align-items: center;
        border-radius: 12px;
        background-color: rgba(11, 16, 51, 0.84);
        transform: translate3d(0px, 60px, 0px) scale3d(1, 1, 1) rotateX(0deg)
          rotateY(0deg) rotateZ(0deg) skew(0deg, 0deg);
        transform-style: preserve-3d;
        transition: transform 0.3s ease;

        .quick-link {
          padding: 4px;
        }
      }
    }

    .job-card-header {
      display: flex;
      align-items: flex-start;

      svg {
        width: 46px;
        padding: 10px;
        border-radius: 8px;
      }
    }
    .menu-dot {
      background-color: var(--color-text-light);
      box-shadow: -6px 0 0 0 var(--color-text-light),
        6px 0 0 0 var(--color-text-light);
      width: 4px;
      height: 4px;
      border: 0;
      padding: 0;
      border-radius: 50%;
      margin-left: auto;
      margin-right: 8px;
    }
    .job-card-wrapper {
      position: relative;
      padding: 5px 16px 20px 16px;

      .job-card-avatar-large {
        position: absolute;
        top: -20px;
        overflow: hidden;
        width: 32px;
        height: 32px;
        margin-right: 12px;
        border: 4px solid #fff;
        border-radius: 100px;
        background-color: var(--color-dark-green);
        box-sizing: border-box;

        img {
          object-fit: cover;
        }
      }
      .job-card-category {
        position: absolute;
        top: -12px;
        right: 16px;
        overflow: hidden;
        height: 26px;
        padding: 4px 8px;
        font-size: 0.7rem;
        font-weight: 600;
        line-height: 0.7rem;
        color: var(--color-blue);
        border: 3px solid #fff;
        border-radius: 17px;
        background-color: var(--color-bg);
        box-sizing: border-box;
      }
    }
    .job-card-time {
      color: var(--color-text-light);
      font-weight: 400;
      margin-top: 20px;
      font-size: 0.8rem;
    }
    .job-card-title {
      font-weight: 600;
      margin-top: 12px;
      font-size: 1.2rem;
    }
    .job-card-subtitle {
      color: var(--subtitle-color);
      font-size: 0.8rem;
      margin-top: 14px;
      line-height: 1.6em;
    }
    .job-card-iterations {
      display: inline;
      .iteration {
        display: inline-block;
        width: 9px;
        height: 9px;
        margin-right: 5px;
        // background: var(--color-light-blue-grey);
        border: 2px solid var(--color-dark-blue);
        border-radius: 100%;
        box-sizing: border-box;

        // &:nth-child(-n + 2) {
        //   border: 2px solid var(--color-orange);
        // }
      }
    }
    .job-detail-buttons {
      .detail-button {
        background-color: var(--color-bg);
        color: var(--color-blue);
        font-size: 11px;
        font-weight: 500;
        padding: 6px 8px;
        border-radius: 4px;
        .material-icons {
          font-size: 1rem;
        }
      }
      .search-buttons {
        border: none;
        background-color: var(--color-bg);
        color: var(--color-blue);
        padding: 8px 10px;
        border-radius: 6px;
        font-size: 11px;
        font-weight: 600;
        margin-top: 14px;
        margin-left: 4px;
      }
    }
    .job-card-buttons {
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 100%;
      margin-top: 4px;

      .card-buttons {
        margin-right: 12px;
      }
    }
  }
}
</style>
